import { toRefs, ref,onMounted,reactive, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router'

export function useManageMenu() {
    const route = useRoute()
    const currentModule = computed(() => {
        return route.meta?route.meta.module?.name:""
    });
    const currentModuleMenuItems = computed(() => {
        if(currentModule.value){
            let m = getModules(currentModule.value,route.params[route.meta.module.column])
            return m
        }
        return []
    });
    function getModules(module_name,id){
        if(module_name == 'event'){
            return [
                {
                    label: '返回活動列表',
                    icon: 'pi pi-arrow-left',
                    to: {name: 'event/list'}
                    
                },
                {
                    separator:true
        
                },
                {
                    label: '活動總覽',
                    icon: 'pi pi-th-large',
                    command: (a) => {},
                    to:  {name: 'event/overview' ,params:{event_code: id}}
                },
                {
                    label: '編輯活動',
                    icon: 'pi pi-pencil',
                    command: () => {
                    },
                    to:  {name: 'event/edit' ,params:{event_code: id}}
                },
                {
                    label: '參加名單',
                    icon: 'pi pi-users',
                    command: () => {
                    },
                    to:  {name: 'event/attendee' ,params:{event_code: id}}
                },
                {
                    label: '活動通知',
                    icon: 'pi pi-megaphone',
                    command: () => {
                    },
                    to:  {name: 'event/notify' ,params:{event_code: id}}
                },
                {
                    label: '提領與帳務',
                    icon: 'pi pi-money-bill',
                    command: () => {
                    },
                    to:  {name: 'event/withdraw' ,params:{event_code: id}}
                },
                {
                    label: '工作人員',
                    icon: 'pi pi-shield',
                    command: () => {
                    },
                    to:  {name: 'event/auth' ,params:{event_code: id}}
                },
                {
                    label: '智慧簽到工具',
                    icon: 'pi pi-qrcode',
                    command: () => {
                    },
                    to:  {name: 'event/auth' ,params:{event_code: id}}
                },
            ]
        }else if(module_name == 'activity'){
            return [
                {
                    label: '點名表',
                    icon: 'pi pi-file',
                    command: (a) => {},
                    to:  {name: 'activity/list' }
                },
                {
                    label: '定期聚會設定',
                    icon: 'pi pi-calendar',
                    command: (a) => {},
                    to:  {name: 'activity/setting/routine' }
                },
                {
                    label: '聚會類別設定',
                    icon: 'pi pi-cog',
                    command: (a) => {},
                    to:  {name: 'activity/setting/type' }
                },
               
                
            ]
        }
    }
     
    const windowSize = ref(window.innerWidth)
    const onResize = ()=>{
        windowSize.value = window.innerWidth
    }
    onMounted (() => addEventListener ('resize', onResize))
    const isDesktop = computed(() => {
        return windowSize.value > 1100
    })
    return { currentModuleMenuItems,isDesktop ,windowSize};
}
